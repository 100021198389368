@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html,
body {
  padding: 0;
  margin: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ko {
  padding-top: 6rem !important;
}
.font-heading {
  text-rendering: geometricprecision !important;
}

.slim {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
h1,
h2,
h3,
h4,
h5,
h5 {
  font-family: font-matter;
  text-rendering: geometricprecision !important;
}
.geo {
  text-rendering: geometricprecision !important;
}
.typed-cursor {
  color: #e5b04f;
  font-family: "Arial Rounded MT Bold", Lato, "Helvetica Neue", Arial, sans-serif;
  transform: translateY(-0.12em) translateX(-0.14em);
  font-size: 1em;
  margin-left: 0.12em;
  display: inline-block;
  user-select: none;
  animation: 1s linear 0s 3 normal forwards running blink;
}
.bg-card {
  @apply !bg-[#fffdf6];
}

.CollapsibleContent {
  overflow: hidden;
}
.CollapsibleContent[data-state="open"] {
  animation: slideDown 300ms ease-out;
}
.CollapsibleContent[data-state="closed"] {
  animation: slideUp 300ms ease-out;
}

.dark .bg-card {
  @apply !bg-[#242526];
}

.tstz {
  z-index: 1000;
  position: relative;
}

.consumer-nav {
  @apply bg-[#f1efe2];
}
.dark .consumer-nav {
  @apply bg-[#242526];
}
.text-consumer {
  @apply text-primary-700;
}
.dark .text-consumer {
  @apply text-[#e4e6eb];
}
.print-hidden {
  display: none;
}

.pdf-version {
  /* Change the font family and font size for the PDF version */
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.cal .tile-main-header {
  text-align: left;
}

.cal .tile-title-container {
  margin: 0;
}

.subby {
  @apply text-sm font-semibold uppercase tracking-wide !text-[#e5b04f];
}

@media (max-width: 740px) {
  .slim {
    padding-top: 0px !important;
  }
  .subby {
    @apply text-center;
  }
}

.tile-title-container {
  margin: 0 auto;
  text-align: center;
  padding-bottom: 46px;
  max-width: 896px;
}

.tile-main-header {
  @apply m-auto mb-4 max-w-4xl text-center font-heading text-4xlt font-normal tracking-[.5px] text-primary-700 @2xl:text-3xlt @3xl:text-4xlt;
}
.tile-subtitle {
  @apply m-auto mt-6 max-w-5xl font-matter text-subtitle font-normal tracking-normal text-primary-600;
}

.geo .tile-container {
  height: 100vh;
  overflow: scroll;
}
.tile-container-sm {
  margin: auto;
  width: 100%;
  max-width: 1800px;
  padding-top: 2.5rem;
  padding-bottom: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media (min-width: 768px) {
  .tile-container-sm {
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.tile-container {
  margin: auto;
  width: 100%;
  flex-shrink: 0;
  max-width: 1600px;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.tile-section {
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.bnbtransition {
  transition:
    box-shadow 0.2s cubic-bezier(0.2, 0, 0, 1),
    transform 0.1s cubic-bezier(0.2, 0, 0, 1);
}
.btnn {
  box-shadow: 0 0 1px transparent;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.2s;
  transition-property: transform;
}
.btnn:active {
  transform: scale(0.96);
}
@media (max-width: 768px) {
  .mbfull {
    padding-left: 0rem;
    padding-right: 0rem;
    min-width: 100%;
  }
}
@media (min-width: 768px) {
  .tile-container {
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .tile-section {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.tile-container-thin {
  @apply m-auto max-w-[1440px] px-6 py-0 sm:px-20 sm:py-0;
}
.tile-btn {
  @apply inline-block w-auto rounded-full bg-primary-700 text-center font-semibold text-white hover:bg-[#0A1A1F];
}

.tpnav .tpnavitem {
  font-weight: 500;
}
.tpnavitem {
  font-weight: 500;
}
.tpnav .tpactive {
  background-color: #fff;
}
.dark .tile-main-header,
.dark .tile-subtitle {
  color: #e4e6eb;
}
.tpnav .tpicon {
  color: #183837;
}
.dark .tpicon {
  color: #fff;
}

.tpnav .tpactive .tpicon {
  color: #e5b04f;
}

code {
  --saf-0: rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
  border: 1px solid var(--saf-0);
  background-color: rgba(var(--sk_foreground_min, 29, 28, 29), 0.04);
  color: rgba(var(--sk_raspberry_red, 224, 30, 90), 1);
  font-variant-ligatures: none;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: normal;
  -webkit-tab-size: 4;
  -moz-tab-size: 4;
  tab-size: 4;
  border-radius: 3px;
  padding: 2px 3px 1px;
  font-size: 12px;
  line-height: 1.50001;
  font-family:
    Monaco,
    Menlo,
    Consolas,
    Courier New,
    monospace !important;
}

.terms p,
.terms ul {
  @apply mb-8;
}
.link-with-chevron::after {
  content: "";
  display: inline-block;
  margin-left: 3px;
  margin-top: 2px;
  background-image: url("/chevron_right.svg"); /* Path to your SVG */
  background-size: contain;
  fill: #068466;
  background-repeat: no-repeat;
  width: 14px; /* Adjust size as needed */
  height: 14px; /* Adjust size as needed */
}

@font-face {
  font-family: matter;
  src: url(../fonts/Matter-Regular.woff2) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: matter;
  src: url(../fonts/Matter-Medium.woff2) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: matter;
}

.haha {
  position: relative;
  padding: 0;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
  transition: box-shadow 150ms;
}
.fancybutton:focus {
  transition: box-shadow 150ms;
  box-shadow: 0px 0px 0px 3px #ffffff;
}
.background-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: grid;
  grid-template-columns: 1fr;
  z-index: -1;
}
.bgg {
  grid-row-start: 1;
  grid-column-start: 1;
}
.scrollable-tabs {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.scrollable-tabs > * {
  flex-shrink: 0; /* Prevent shrinking of the children */
}

/* Add a CSS class to target the first tab specifically */
.scrollable-tabs > :first-child {
  min-width: 60px; /* Adjust this value as needed */
}

.background-1 {
  animation: background-1-animation 8s infinite;
  background-image: linear-gradient(165deg, hsl(178, 100%, 44%), hsl(209, 100%, 44%));
  border-radius: 5px;
}
.background-1:before {
  content: "";
  position: absolute;
  top: 0.7rem;
  left: 0.6rem;
  bottom: 0.7rem;
  right: 0.6rem;
  background-image: linear-gradient(165deg, hsl(178, 100%, 44%), hsl(209, 100%, 44%));
  filter: blur(35px);
}
.background-2 {
  animation: background-2-animation 8s infinite;
  background-image: linear-gradient(165deg, hsl(0, 84%, 61%), hsl(47, 95%, 57%));
  border-radius: 5px;
}
.haha {
  min-width: 326px;
  min-height: 64px;
}
.background-2:before {
  content: "";
  position: absolute;
  top: 0.7rem;
  left: 0.6rem;
  bottom: 0.7rem;
  right: 0.6rem;
  background-image: linear-gradient(165deg, hsl(0, 84%, 61%), hsl(47, 95%, 57%));
  filter: blur(35px);
}
.background-3 {
  animation: background-3-animation 8s infinite;
  background-image: linear-gradient(165deg, hsl(330, 100%, 50%), hsl(270, 67%, 46%));
  border-radius: 5px;
}
.mapboxgl-ctrl-attrib-inner {
  display: none !important;
}

.dot {
  display: inline-block;
  animation-name: bounce;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 0.8s;
}

.dot-1 {
  animation-delay: 0s;
}

.dot-2 {
  animation-delay: 0.2s;
}

.dot-3 {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
}

.intro-image .mux-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.button-text {
  transition: background-color 0.3s ease;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: black;
  margin: 1px;
  border-radius: 5px;
  padding: 0.75rem 3rem;
  font-size: 1rem;
  transition: all 100ms;
  min-width: 326px;
  min-height: 64px;
  transition: background-color 0.3s ease;
}
.fancybutton.ready-to-navigate .button-text {
  background-color: #e5b04f;
}

.background-3:before {
  content: "";
  position: absolute;
  top: 0.7rem;
  left: 0.6rem;
  bottom: 0.7rem;
  right: 0.6rem;
  background-image: linear-gradient(165deg, hsl(330, 100%, 50%), hsl(270, 67%, 46%));
  filter: blur(35px);
}
@keyframes background-1-animation {
  0%,
  16.667%,
  to {
    opacity: 1;
  }

  33%,
  83.333% {
    opacity: 0;
  }
}

@keyframes background-2-animation {
  0%,
  16.667%,
  66.667%,
  to {
    opacity: 0;
  }

  33.333%,
  50% {
    opacity: 1;
  }
}

@keyframes background-3-animation {
  0%,
  50%,
  to {
    opacity: 0;
  }

  66.667%,
  83.333% {
    opacity: 1;
  }
}
mux-player {
  --play-button: none;
  --live-button: none;
  --seek-backward-button: none;
  --seek-forward-button: none;
  --mute-button: none;
  --captions-button: none;
  --airplay-button: none;
  --pip-button: none;
  --fullscreen-button: none;
  --cast-button: none;
  --playback-rate-button: none;
  --volume-range: none;
  --time-range: none;
  --time-display: none;
  --duration-display: none;
  --media-object-fit: cover;

  position: absolute;
  inset: 0;
  z-index: 0;
}

mux-player::part(gesture-layer) {
  display: none;
}

.hidden-col {
  display: none;
}
.bg-border {
  background-color: #ddd;
}
.doctitle {
  max-width: 90px;
  font-weight: 500;
  line-height: 1.1;
  font-size: 14px;
  text-align: center;
  mix-blend-mode: difference;
  display: block;
  color: #f9f9f9;
  z-index: 999999999999999;
  position: absolute;
  top: 61px;
  left: 17px;
}

.styles-logo {
  position: fixed;
  top: 1.5rem;
  left: 1.5rem;
  mix-blend-mode: difference;
  display: block;
  color: var(--c_element-white);
  height: 2rem;
  z-index: 9999999999999;
}
.intro-image {
  background: linear-gradient(172.59deg, #121316 6.34%, rgba(18, 19, 22, 0) 85.69%),
    linear-gradient(180deg, #0a3726, #0a3726 0.01%, #061f16);
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

[data-nextjs-scroll-focus-boundary] {
  display: contents;
}

.bg-gradient-to-t {
  background-image: linear-gradient(0deg, var(--tw-gradient-stops));
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(90deg, var(--tw-gradient-stops));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-b {
  background-image: linear-gradient(180deg, var(--tw-gradient-stops));
}
.max-h-309 {
  max-height: 19.3125rem;
}
.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.data-header-overlay .data-header-overlay\:transition-colors {
  transition-property:
    color,
    background-color,
    border-color,
    fill,
    stroke,
    -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

@keyframes ticker-kf {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-80rem, 0, 0);
  }
}

.img-ticker {
  animation: ticker-kf 32s linear infinite;
}

@keyframes ticker-kf-reverse {
  0% {
    transform: translate3d(-80rem, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.img-ticker-reverse {
  animation: ticker-kf-reverse 32s linear infinite;
}

@keyframes ticker-kf-logos {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-20rem, 0, 0);
  }
}

.img-ticker-logos {
  animation: ticker-kf-logos 18s linear infinite;
}

.h-75vw\/4 {
  height: 18.75vw;
}
.bg-gradient-to-l {
  background-image: linear-gradient(270deg, var(--tw-gradient-stops));
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}

.bg-gradient-black-fade-down {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 27.08%, transparent 89.06%);
}

.bg-gradient-black-fade-up {
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5) 31.25%, rgba(0, 0, 0, 0.8));
}

.bg-gradient-blue-fade-down {
  background-image: linear-gradient(180deg, #004677 44.79%, rgba(0, 70, 119, 0) 56.25%);
}

.bg-gradient-gray-100-fade-down {
  background-image: linear-gradient(
    180deg,
    #f9f9f9 21.88%,
    hsla(0, 0%, 97.6%, 0.9019607843137255) 42.71%,
    hsla(0, 0%, 97.6%, 0)
  );
}

.bg-gradient-white-fade-down {
  background-image: linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0));
}

@layer base {
  body {
    @apply bg-gray-50 font-sans  text-gray-900 antialiased dark:bg-gray-800 dark:text-gray-100;
    @apply min-h-screen;
  }

  ::selection {
    @apply bg-primary-700 text-neutral-0;
  }
}

button:disabled,
button[disabled] {
  @apply cursor-not-allowed bg-neutral-300 opacity-50;
}

.MuiDataGrid-main {
  @apply font-sans tracking-normal antialiased !important;
}
.MuiCheckbox-root:hover,
.MuiIconButton-root:hover {
  background-color: transparent !important;
}
.MuiDataGrid-cell {
  @apply border-r border-black/[7%] font-sans font-medium  tracking-normal last:border-r-0 dark:border-white/[7%]  !important;
}
.MuiDataGrid-columnHeaderTitle {
  @apply text-secondary text-[14px] font-semibold leading-none tracking-normal antialiased !important;
}
.MuiButtonBase-root {
  @apply flex items-center gap-x-3 border-b border-black/[7%]  p-1 px-6 py-3 font-sans text-2xs text-sm font-medium text-base-disabled dark:border-white/[7%]  dark:text-gray-100 !important;
}
.MuiButtonBase-root {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.bnb {
  height: 100% !important;
  width: 45% !important;
  min-width: 100% !important;
  position: absolute !important;
  z-index: 1 !important;
  top: 0px !important;
  left: 0px !important;
  background: -webkit-linear-gradient(117.94deg, #000000 2.61%, rgba(0, 0, 0, 0) 62.19%) !important;
  background: -moz-linear-gradient(117.94deg, #000000 2.61%, rgba(0, 0, 0, 0) 62.19%) !important;
  background: linear-gradient(117.94deg, #000000 2.61%, rgba(0, 0, 0, 0) 62.19%) !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  pointer-events: none !important;
}
.MuiInputBase-input,
.MuiPickersYear-yearButton,
.MuiPickersCalendarHeader-labelContainer {
  @apply text-primary !important;
  @apply font-sans !important;
  @apply font-medium !important;
}
.hero--cover {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%);
  user-select: none;
  pointer-events: none;
}

.hero--cover--bg-gradient-white {
  background: linear-gradient(89.5deg, #fbfbf6 0.4%, rgba(255, 255, 255, 0.8) 49.57%, rgba(251, 251, 246, 0) 99.54%);
}

.MuiDataGrid-columnHeaders {
  @apply border-t-0 border-black/[7%] text-sm  group-hover:bg-neutral-100 dark:border-white/[7%];
}
.MuiDataGrid-columnSeparator {
  @apply opacity-0 !important;
}
.MuiDataGrid-columnHeader,
.MuiDataGrid-columnHeaders {
  @apply rounded-none border-r border-black/[7%] dark:border-white/[7%]  !important;
}
.MuiDataGrid-pinnedRows {
  @apply border-t border-t-skeleton bg-neutral-0 font-sans text-gray-900 antialiased dark:bg-neutral-dark-0 dark:text-gray-100;
}
.MuiDataGrid-root {
  @apply bg-main-background border-r-0 border-t border-transparent text-gray-900 last:border-r-0 dark:text-gray-100 !important;
}
.MuiDataGrid-pinnedColumnHeaders {
  @apply bg-main-background border-black/[7%] dark:border-white/[7%] dark:bg-gray-800  dark:text-gray-100;
}
.MuiDataGrid-pinnedColumns {
  @apply bg-main-background border-r  border-black/[7%] shadow-none dark:border-white/[7%] dark:bg-gray-800 !important;
}

.MuiDataGrid-pinnedColumns,
.MuiDataGrid-pinnedColumns--left,
.MuiDataGrid-pinnedColumns--right,
.MuiDataGrid-pinnedColumnHeaders {
  @apply bg-main-background font-sans shadow-none dark:bg-gray-800 !important;
}

.MuiDataGrid-filler--pinnedLeft {
  border-right: 0 !important;
}

.MuiDataGrid-pinnedColumns--right,
.MuiDataGrid-pinnedColumnHeaders--right {
  @apply border-l;
}

.MuiDataGrid-bottomContainer.MuiDataGrid-container--bottom {
  bottom: 0 !important;
}

.MuiCheckbox-root svg {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: 1px solid;
  border-radius: 4px;
  @apply border-black/[7%] bg-neutral-0 dark:border-white/[7%]  dark:bg-neutral-dark-0;
}
.MuiDataGrid-menu {
  @apply min-w-[250px] rounded-lg bg-neutral-0 text-sm font-medium text-gray-900 focus:outline-none dark:border-white/[7%]  dark:bg-neutral-dark-0 dark:text-gray-100 !important;
}
.MuiPaper-root {
  @apply bg-dropdown  scale-100 transform rounded-lg border border-black/[7%] opacity-100 shadow-lg focus:outline-none dark:border-white/[7%] !important;
}
.MuiPickersCalendarHeader-label,
.MuiDayCalendar-weekDayLabel,
.MuiClock-squareMask,
.MuiClockNumber-root,
.MuiListItemText-root {
  @apply text-primary;
}
.MuiClock-pin,
.MuiClockPointer-root,
.MuiClockPointer-thumb {
  @apply border-primary-700 bg-primary-700;
}
.MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-notchedOutline:hover {
  @apply border-black/[7%] dark:border-white/[7%] !important;
}
.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  @apply border-2 border-[#1976d2] !important;
}
.MuiIconButton-root:hover {
  background-color: transparent;
}
.DataGridView--row--sidebar-open .MuiDataGrid-cell:focus {
  outline: none !important;
}
.MuiDataGrid-cell,
.MuiDataGrid-cell--withRenderer,
.MuiDataGrid-cell {
  @apply border-b !border-black/[7%] dark:border-white/[7%];
}
.MuiDataGrid-columnHeader,
.css-1w6uqe3-MuiDataGrid-root .MuiDataGrid-cell {
  @apply border-black/[7%] dark:border-white/[7%];
}
.dark .MuiDataGrid-columnHeader,
.dark .MuiDataGrid-columnHeaders {
  @apply border-white/[7%];
}
.DataGridView--row-highlight {
  @apply bg-[#0000000a];
}
.MuiListItemIcon-root {
  min-width: 16px !important;
}
.MuiSvgIcon-root {
  @apply text-icons h-4 w-4 shrink-0 !important;
}

@layer utilities {
  .lightpop {
    box-shadow:
      0 0 0 1px rgba(204, 204, 204, 0),
      0px 0px 0px 1px rgba(0, 0, 0, 0.02),
      0px 1px 4px 0px rgba(0, 0, 0, 0.12);
  }

  .lin {
    border-radius: 8px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 9%);
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-duration: 0.1s;
    animation-name: none;
    animation-duration: 1.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }
  .pop {
    box-shadow: rgb(0 0 0 / 50%) 0px 16px 70px;
  }
  .lin:hover {
    transition-duration: 0.1s;
    transition-property: box-shadow;
  }
  .darklin {
    box-shadow:
      rgb(62 64 68) 0px 0px 0px 1px,
      rgb(0 0 0 / 28%) 0px 1px 4px 0px;
    border-radius: 8px;
    transition:
      background-color 140ms cubic-bezier(0.7, 0, 0.39, 0.98) 0s,
      border-color 140ms cubic-bezier(0.7, 0, 0.39, 0.98) 0s;
  }
  .darklin:hover {
    transition-duration: 0.1s;
    transition-property: box-shadow;
    /* This line generate that in dark mode hover the card show all white */
    /* background-color: rgb(251, 251, 252); */
    cursor: pointer !important;
  }

  .react-datepicker {
    @apply bg-white !important;
    @apply text-gray-900  !important;
    @apply border border-black/[7%]  !important;
    @apply rounded-lg  !important;
  }

  .dark .react-datepicker {
    @apply bg-gray-900  !important;
    @apply text-gray-100  !important;
    @apply border border-white/[7%]  !important;
    @apply rounded-lg  !important;
  }

  .react-datepicker__header {
    @apply border border-black/[7%];
  }

  .react-datepicker__time-container--with-today-button {
    @apply border-l border-black/[7%];
  }
  .dark .react-datepicker__time-container--with-today-button {
    @apply border-l border-white/[7%];
  }

  .react-datepicker__day:hover {
    @apply bg-gray-100;
  }

  .react-datepicker__time-container {
    @apply border-l border-black/[7%];
  }
  .dark .react-datepicker__time-container {
    @apply border border-l border-white/[7%];
  }

  .dark .react-datepicker__day:hover {
    @apply bg-gray-700;
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day--selected:hover {
    @apply bg-gray-100;
  }

  .dark .react-datepicker__day--keyboard-selected:hover,
  .dark .react-datepicker__day--selected:hover {
    @apply bg-gray-700;
  }
}
.MuiMenuItem-root {
  @apply mx-2 rounded-lg px-2.5 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-dark-100 !important;
}
.MuiMenuItem-root .MuiTypography-root {
  @apply font-sans text-sm font-medium text-gray-900 dark:text-gray-100 !important;
}
.MuiPickersDay-root {
  @apply !p-0;
}
.MuiPickersPopper-root {
  left: 12px;
  z-index: 50;
}

/* Update styles for react-toastify */
:root {
  --toastify-toast-min-height: 48px !important;
  --toastify-toast-width: 340px !important;
}
.Toastify__toast {
  border-radius: 8px !important;
  margin-bottom: 16px !important;
}

@layer components {
  .text-primary {
    @apply text-gray-900;
  }

  .dark .text-primary {
    @apply text-gray-100;
  }

  /* Lighter secondary text in the app */
  .text-secondary {
    @apply text-gray-500;
  }

  .dark .text-secondary {
    @apply text-gray-400;
  }

  /* Icon colors in the app */
  .text-icons {
    @apply text-gray-500;
  }

  .dark .text-icons {
    @apply text-gray-400;
  }

  /* Background color of the app */
  .bg-background {
    @apply bg-gray-50;
  }

  .bg-main-background {
    @apply bg-white;
  }
  .dark .bg-main-background {
    @apply bg-gray-900;
  }
  .admin-bg-main {
    @apply text-gray-900;
  }
  .dark .admin-bg-main {
    @apply text-gray-100;
  }

  .dark .bg-background {
    @apply bg-gray-800;
  }

  .admin-bg-sidebar {
    @apply bg-gray-100;
  }

  .dark .admin-bg-sidebar {
    @apply bg-gray-900/30;
  }

  /* Border color of the app */
  .bg-separator {
    @apply bg-black/[7%];
  }

  .dark .bg-separator {
    @apply bg-white/[7%];
  }

  .consumer-tabs {
    @apply bg-card;
  }
  .bg-muted {
    @apply bg-[#183837] text-white;
  }
  .dark .bg-muted {
    @apply bg-[#183837] text-white;
  }

  /* Border color of the app */
  .border-separator {
    @apply border-black/[7%];
  }
  .clientsp {
    @apply bg-consumer;
  }

  .bg-consumer {
    @apply bg-[#f1efe2];
  }
  .border-consumer {
    @apply border-black/[7%];
  }
  .dark .border-consumer {
    @apply border-black/[7%];
  }
  .dark .bg-consumer {
    @apply bg-[#18191A];
  }
  .dark .border-separator {
    @apply border-white/[7%];
  }

  /* TODO: Replace old styles with these after `appnav` feature */
  .appnav .bg-background {
    @apply bg-gray-100;
  }

  .navtext {
    @apply text-base-disabled;
  }
  .dark .navtext {
    @apply text-base-disabled;
  }

  .dark .appnav .bg-background {
    @apply bg-gray-900;
  }

  /* Border color of the app */
  .appnav .bg-separator {
    @apply bg-black/[5%];
  }

  .dark .appnav .bg-separator {
    @apply bg-white/[5%];
  }

  /* Border color of the app */
  .appnav .border-separator {
    @apply border-black/[5%];
  }

  .dark .appnav .border-separator {
    @apply border-white/[5%];
  }

  /* Border color of background color alias */
  .border-background {
    @apply border-gray-50;
  }

  .dark .border-background {
    @apply border-gray-800;
  }

  /* TODO: Replace old styles with these after `appnav` feature */
  .appnav .border-background {
    @apply border-gray-50;
  }

  .dark .appnav .border-background {
    @apply border-gray-900;
  }

  /* Background color of dropdowns */
  .bg-dropdown {
    @apply bg-white;
  }

  .dark .bg-dropdown {
    @apply bg-gray-900;
  }

  /* TODO: Replace old styles with these after `appnav` feature */
  .appnav .bg-dropdown {
    @apply bg-white;
  }

  .dark .appnav .bg-dropdown {
    @apply bg-gray-750;
  }

  /* Background color of dropdowns */
  .border-dropdown {
    @apply border-white;
  }

  .dark .border-dropdown {
    @apply border-gray-900;
  }

  /* TODO: Replace old styles with these after `appnav` feature */
  .appnav .border-dropdown {
    @apply border-white;
  }

  .dark .appnav .border-dropdown {
    @apply border-gray-750;
  }

  /* Background color of popovers */
  .bg-popover {
    @apply bg-white;
  }

  .dark .bg-popover {
    @apply bg-gray-700;
  }

  /* TODO: Replace old styles with these after `appnav` feature */
  .appnav .bg-popover {
    @apply bg-white;
  }

  .dark .appnav .bg-popover {
    @apply bg-gray-800;
  }

  /* Background color of popovers */
  .border-popover {
    @apply border-white;
  }

  .dark .border-popover {
    @apply border-gray-700;
  }

  /* TODO: Replace old styles with these after `appnav` feature */
  .appnav .border-popover {
    @apply border-white;
  }

  .dark .appnav .border-popover {
    @apply border-gray-800;
  }
}
.custom-ease {
  transition-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
}

/* Styles to change scrollbar */ /* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #8f959c4c;
  border: 3px solid transparent;
  opacity: 0.2;
  border-radius: 12px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #56677a4c;
}

/* These styles are necessary to show/hide the button expand in Data grid */
.datagrid-row-expand {
  opacity: 0;
}

.datagrid-row-exposed-actions {
  opacity: 0;
}

.Mui-hovered .datagrid-row-exposed-actions {
  opacity: 1;
}

.Mui-hovered .datagrid-row-expand {
  opacity: 1;
}

/* For title edit */
[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #cecbc2;
}

.animate-bounce-left {
  animation: bounce-left 0.5s ease infinite;
}
@keyframes bounce-left {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
}
.animate-bounce-right {
  animation: bounce-right 0.5s ease infinite;
}
@keyframes bounce-right {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}
@keyframes fade-in {
  0% {
    filter: opacity(0);
  }
  50% {
    filter: opacity(0);
  }
  100% {
    filter: opacity(1);
  }
}

@keyframes button-depress {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes icon-depress {
  50% {
    transform: scale(1.1) rotate(-20deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

@keyframes button-press {
  100% {
    transform: scale(0.875);
  }
}

@keyframes icon-press {
  100% {
    transform: scale(1.3) rotate(20deg);
  }
}

.rating-buttons {
  animation: fade-in 1s ease-out 0s forwards;
}

.rating-button {
  animation: button-depress 0.5s ease-out 0s forwards;
  border-radius: 50%;
  position: relative;
}

.rating-button svg {
  animation: icon-depress 0.5s ease-out 0s forwards;
  position: relative;
}

.rating-button.dislike {
  margin-right: 0.75rem;
}

.rating-button.dislike svg {
  top: 1px;
}

.rating-button.like svg {
  top: -1px;
}

.rating-button:not([disabled]):active {
  animation: button-press 0.25s ease-out 0s forwards;
}

.rating-button:not([disabled]):active svg {
  animation: icon-press 0.25s ease-out 0s forwards;
}
